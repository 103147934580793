@use '../../global_styles/variables';

.Toast {
    position: fixed;
    z-index: 2;
    text-align: center;
    top: 10%;
    height: 0 auto;
    width:250px;
    left: 0;
    right:0;
    margin:auto;
    border-radius: 4px;
    background-color: variables.$sg-dark;
    color: variables.$sg-light;
    box-shadow: 0px 0px 5px 0px;
}

.Toast-Animation-enter-active {
    animation: openToast 0.3s ease-out forwards;
}

.Toast-Animation-exit-active {
    animation: closeToast 0.5s ease-out forwards;
}

@keyframes openToast {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    50% {
        opacity: 1;
        transform: translateY(+90%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closeToast {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 0.5;
        transform: translateY(+60%);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}