.warningOverlay{
    position: fixed;
    top:70px;
    bottom:0;
    left:0;
    right:0;
    background-color: #dc3545;
    z-index: 1;
    width: 100%;
    height:1px;
}

.warningOverlay-enter-active{
    animation: openWarningOverlay 0.1s ease-out forwards;
}

.warningOverlay-exit-active{
    animation: closeWarningOverlay 0.1s ease-out forwards;
}

@keyframes openWarningOverlay {
    0% {
        opacity: 0;

    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0.8;
    }
}

@keyframes closeWarningOverlay {
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}