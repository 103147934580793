@use '../../global_styles/variables';

.Modal {
    position: fixed;
    z-index: 1;
    border: 1px solid variables.$sg-grey;
    box-shadow: 0 7px 7px variables.$sg-dark;
    text-align: center;
    box-sizing: border-box;
    background-color:variables.$sg-light;
    top:30%;
    bottom: auto;
    padding:7px;
    height: 0 auto;
    left: 0;
    right:0;
    margin:auto;
    max-width: 50%;
    z-index: 2;
}

.modal-Text-Wrapper {
    margin: 30px;
    padding:20px;
    border-left: 3px solid variables.$sg-blue;
}

.modal-Text-Wrapper .fas.fa-trash-alt{
    font-size: 3rem;
    padding-bottom:1.5rem;
    color:variables.$sg-blue;
}

.modal-Button-Wrapper {
    margin: 0px auto;
    padding:3px;
}

.Modal button {
    margin:10px;
}

.Modal-Animation-enter-active {
    animation: openModal 0.3s ease-out forwards;
}

.Modal-Animation-exit-active {
    animation: closeModal 0.5s ease-out forwards;
}

@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    50% {
        opacity: 1;
        transform: translateY(90%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closeModal {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 0.5;
        transform: translateY(+60%);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.Backdrop {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: variables.$sg-dark;
    opacity:0.5;
    z-index: 1;
}

.Backdrop-Animation-enter-active {
    animation: openBackdrop 0.3s ease-out forwards;
}

.Backdrop-Animation-exit-active {
    animation: closeBackdrop 0.5s ease-out forwards;
}

@keyframes openBackdrop {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes closeBackdrop {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}