html {
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .explanation {
    cursor: help;
  }
  
  .mce-content-body {
    width: 100%;
  }
  
  .mceToolbar td {
    display:table-row;
    float: left;
    
  }
  .mceToolbar td:nth-of-type(11){
    clear: left;
    max-width: 100px;
  }
  
  .collapseableField > Card{
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .ModalKiller {
    color:black;
    position: absolute;
    right:0.8rem;
    top:0.8rem;
    font-size: 1.5rem;
    text-shadow: -1px 0 #ced4da, 0 1px #ced4da, 1px 0 #ced4da, 0 -1px #ced4da;
  }
  
  .ModalKiller:hover {
    color:#0069d9;
  }
  
  .messageWrapper {
    padding:10px;
    margin-bottom:20px;
    border-left: 3px solid #0069d9;
    min-height: 4rem;
  }
  
  .messageIcon {
    font-size: 3rem;
    color:#0069d9;
    margin-bottom:1rem;
  }
  
  .toTopButton {
    font-size: 2rem;
    color: #007BFF;
    -webkit-text-stroke: 1px black;
  }
  
  .toTopButton:hover {
    color:#0069d9;
  }
  
  .fa-question:hover{
    color: #007BFF;
  }
  
  .redMark {
    color:#dc3545;
  }
  
  .greenMark {
    color: #D1E751;
  }
  
  .yellowMark {
    color: yellow;
  }
  
  .sheetNotice {
      color:#C82333;
  }
  
  .sheetNotice:hover {
      transform: scale(1.3);
    transition: transform 0.2s ease-out;
  }

  @media only screen and (max-width: 983px) {
    .toTopButton{
      position: fixed;
      right: 1rem;
      bottom:8rem;
    }
  }