$sg-dark: #1a1a1a;
$sg-dark2: #272727;
$sg-dark-transparent: rgba(0,0,0,0.75);
$sg-light: #f5f5f5;
$sg-light-transparent:rgba(255,255,255, 0.8);
$sg-green: #D1E751;
$sg-red: #dc3545;
$sg-light-red: #F8D7DA;
$sg-blue:#0d6efd;
$sg-white:#fdfdfd;
$sg-grey:#CED4DA;