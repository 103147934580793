@use '../../global_styles/variables';

#loadingWrapper {
  position: fixed;
  top: 0;
  bottom:0;
  left:0;
  right: 0;
  background-color: variables.$sg-dark-transparent;
  color: black;
  z-index: 15;
}

#loading {
  position: fixed;
  top:20%;
  left:10%;
  right:10%;
  padding: 10px;
  border-radius: 2px;
  text-align: center;
}

#loadingrotation {
    animation: rotation 1s infinite steps(8);
    transform-style: flat;
    stroke-width: 1px;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.loadingText {
  position: relative;
  margin-top: 25px;
  color:variables.$sg-light;
  font-size: 3em;
  text-align: center;
}

.loadingText span{
  display: inline-block;
  transition: all 500ms ease;
  animation: wave 2s ease  infinite;
}

.loadingText span:nth-child(1){
  animation-delay: 0;
}
.loadingText span:nth-child(2){
  animation-delay: 100ms;
}
.loadingText span:nth-child(3){
  animation-delay: 200ms;
}
.loadingText span:nth-child(4){
  animation-delay: 300ms;
}
.loadingText span:nth-child(5){
  animation-delay: 400ms;
}
.loadingText span:nth-child(6){
  animation-delay: 500ms;
}
.loadingText span:nth-child(7){
  animation-delay: 600ms;
}
.loadingText span:nth-child(8){
  animation-delay: 700ms;
}
.loadingText span:nth-child(9){
  animation-delay: 800ms;
}
.loadingText span:nth-child(10){
  animation-delay: 900ms;
}

@keyframes wave{
    0%, 40%, 100% { 
      transform: translate(0, 0);
      color:variables.$sg-light;   
    }
    10% { 
      transform: translate(0, -15px); 
      color: variables.$sg-blue;  
    }  
}

@keyframes ldio-34f2r4bk1ps-o {
  0%    { opacity: 1; transform: translate(0 0) }
 49.99% { opacity: 1; transform: translate(80px,0) }
 50%    { opacity: 0; transform: translate(80px,0) }
100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-34f2r4bk1ps {
  0% { transform: translate(0,0) }
 50% { transform: translate(80px,0) }
100% { transform: translate(0,0) }
}
.ldio-34f2r4bk1ps div {
position: absolute;
width: 80px;
height: 80px;
border-radius: 50%;
top: 60px;
left: 20px;
}
.ldio-34f2r4bk1ps div:nth-child(1) {
background: variables.$sg-blue;
animation: ldio-34f2r4bk1ps 1s linear infinite;
animation-delay: -0.5s;
}
.ldio-34f2r4bk1ps div:nth-child(2) {
background: variables.$sg-green;
animation: ldio-34f2r4bk1ps 1s linear infinite;
animation-delay: 0s;
}
.ldio-34f2r4bk1ps div:nth-child(3) {
background: variables.$sg-blue;
animation: ldio-34f2r4bk1ps-o 1s linear infinite;
animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-tlktrepq2 {
display: absolute;
width: 200px;
height: 200px;
margin: auto;
overflow: hidden;
}
.ldio-34f2r4bk1ps {
width: 100%;
height: 100%;
position: relative;
transform: translateZ(0) scale(1);
backface-visibility: hidden;
transform-origin: 0 0;
}
.ldio-34f2r4bk1ps div { box-sizing: content-box; }